/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// footer 

.footer-nav-menu {
	margin: 0px;
	li {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		list-style-type: none;
		font-size: 13px;
		margin: 0px 10px;
		cursor: pointer;
		font-weight: 700;
		height: 35px;
		/*padding: 0 20px;*/

		&:hover{
			/*background-color: #262626;*/
		}

		a{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@include mq("tablet", "max") {
	    padding: 0;
	    padding-top: 25px;
	}
}