/*
 * Flex UX Framework
 *
 * Filename: item-customization.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.artwork-control {
    line-height: 20px;
    padding-top: 10px;
    max-width: 430px;
}