/*
 * Flex UX Framework
 *
 * Filename: custom-orders-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

/*
.custom-orders { 

	.page-body {

		p {
    		font-size: 14px;
    		line-height: 1.5em;
    		margin-top: 10px;
		}

		a {
	    	color: $brand-color;
		}
	}
}
*/


.custom-orders { 

	.page-body {

		.custom-order-form {
			display: flex;
			flex-flow: column;
			margin-left: auto;
			margin-right: auto;
			/*width: 30%; */
			margin-top: 10px;
			margin-bottom: 50px;

			@include mq('tablet', 'max') {
				/*width: 95%;*/
			}

			input, textarea, select {
				margin-bottom: 15px;
				border: 1px solid #e0e0e0;
				padding: 10px;
				font-family: $primary-font-face;
				font-size: 14px;
			}

			select {
				padding: 5px;
				color: #000;
			}

			button {
				border: none;

				&:hover {
					cursor: pointer;
				}
			}
			
		}

		p {
    		font-size: 14px;
    		line-height: 21px;
		}
	}

	input{
		color: #000;	
	}

		::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: #000;
		  opacity: 1; /* Firefox */
		}

		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: #000;
		}

		::-ms-input-placeholder { /* Microsoft Edge */
		  color: #000;
		}		
}