/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)

.about-us {
	max-width: 948px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	background: white;
	padding: 25px 0px;
	text-align: center;
	line-height: 1.5em;
	font-family: $primary-font-face;
	margin: 24px auto 25px auto;

	@include mq("phone-wide", "max"){
		margin: 16px 0px;
	    padding: 20px;
	}

	.title{
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
	}
	
	h1{
		font-family: $primary-font-face;
		font-size: 30px;
		text-align: center;
		font-weight: 700;
		// color: #008733;
		margin: 0 0 15px 0;
		line-height: 1em;
	}

	hr{
		align-self: center;
		//margin-top: 1.5vh !important;
		height: 2px !important;
		background-color: $brand-color-secondary!important;
		height: 2px;
		width: 50px;
		outline: none;
		border: none;
		margin: 0 0 19px 0;
	}
	
	.copy{
		max-width: 620px;
		
		p{
			line-height: 1.5em;
			padding: 0px 12px;

			@include mq('phone-wide', 'min'){
				padding: 0 20px;
			}
		}
	}
}
